let yf = {};

yf.pageControllers = [];

yf.baseController = {
  initialize() {
    $('A.lightbox').fancybox({
      nextEffect: 'none',
      prevEffect: 'none',
      openEffect: 'none',
      closeEffect: 'none',
      openSpeed: 0,
      closeSpeed: 0,
      nextSpeed: 0,
      prevSpeed: 0,
      preload: 20,
      helpers: {
        title: {
          type: 'inside'
        }
      }
    });

    const loginInvoker = $('.login-invoker');

    loginInvoker.on('mousedown', (ev) => {
      if (ev.button === 1) {
        ev.stopPropagation();
        ev.preventDefault();
        console.log('login clicked');
        $(this).trigger('click');
      }
    });

    loginInvoker.fancybox({
      helpers: {
        overlay: {
          closeClick: false // if true, fancyBox will be closed when user clicks on the overlay
        }
      },
      type: 'ajax',
      closeBtn: true,
      beforeShow() {
        yf.baseController.transformLogin(this);
      }
    });

    const resettingInvoker = $('.resetting-invoker');
    resettingInvoker.fancybox({
       helpers: {
          overlay: {
             closeClick: false  // if true, fancyBox will be closed when user clicks on the overlay
          }
       },
       type: 'ajax',
       closeBtn: true,
       beforeShow() {
           yf.baseController.transformResetting(this);
       }
    });
  },
  transformLogin(fbInstance) {
    console.log('transform login');
    const fbInner = $('.fancybox-inner');
    const form = fbInner.find('form');

    fbInner.on('click', '.login-button', (e) => {
      e.preventDefault();
      form.submit();
    });

    fbInner.on('click', '.close-button', (e) => {
      e.preventDefault();
      $.fancybox.close();
    });
/*
    form.on('submit', (e) => {
      console.log('form submit');

      e.preventDefault();

      const formData = $.merge([
        {name: 'xhr', value: 1},
      ], form.serializeArray());

      const loader = fbInner.find('.loader');
      loader.show();

      // $.ajax({
      //   type: 'POST',
      // });
    });
*/
  },

  transformResetting: function(fbInstance) {
      const fbInner = $('.fancybox-inner');

      fbInner.on('click', '.request-button', function(e) {
        e.preventDefault();
        $(this).closest('form').submit();
      });

      fbInner.on('click', '.cancel-button, .close-button, .close-link', function(e) {
        e.preventDefault();
        $.fancybox.close();
      });

      fbInner.on('submit', 'form', function(e) {
          console.log('submit resetting');
          let form = fbInner.find('form');
          let formData = $.merge([{name: 'xhr', value: 1}], form.serializeArray());
          let loader = fbInner.find('.loader');
          loader.show();
          e.preventDefault();
          $.ajax({
             type: 'POST',
             url: form.attr('action'),
             data: formData,
             success: function(data, textStatus, jqXHR) {
                 $('#resetting-layer').replaceWith(data);
             },
             complete: function() {
                 loader.hide();
             }
          });
          return false;
        });
    },

  // initialize: function () {
    // if (!Modernizr.objectfit) {
    //   $("[class*='yf-img-container']").each(function() {
    //     var $container = $(this);
    //     var img = $container.find('img');
    //
    //     if (img) {
    //       var imgUrl = img.prop('src');
    //       $(img).detach();
    //
    //       if (imgUrl)
    //         $container.css('backgroundImage', 'url(' + imgUrl + ')');
    //     }
    //   });
    // }

    // if (Modernizr.mq('(min-width: 768px)')) {
    //   $('.yf-card-list').each(function () {
    //     var heightMax = 0;
    //     $('.yf-card', this).each(function () {
    //       heightMax = Math.max($(this).height(), heightMax);
    //     });
    //     $('.yf-card', this).height(heightMax);
    //   });
    // }
  // }
};

yf.pageControllers.homepage = {
    initialize: function(page) {
        $('A.lightbox').fancybox({
            nextEffect: 'none',
            prevEffect: 'none',
            openEffect: 'none',
            closeEffect: 'none',
            openSpeed: 0,
            closeSpeed: 0,
            nextSpeed: 0,
            prevSpeed: 0,
            preload: 20,
            helpers: {
                title: {
                    type: 'inside'
                }
            }
        });

        var popup = $('#popup');
        if (popup.length) {
            popup.find('#popup-logo').removeAttr('width');
            $.fancybox(popup.html(), {
                'width': 600
            });

            var fancyBoxInner = $('.fancybox-inner');
            fancyBoxInner.on('click', '.close-button', function (e) {
                e.preventDefault();
                $.fancybox.close();
            });
        }
    }
};

yf.pageControllers.apps = {
  initialize(page) {
    let apps = {};
    let category = 0;

    const categoryButtons = $('.category-button');

    categoryButtons.each(() => {
      const id = $(this).attr('data-category');
      if ($(this).hasClass('active'))
        category = id;
      apps[id] = $('.app-list li').filter(() => {
        return $(this).attr('data-categories').indexOf(id) >= 0;
      });
    });

    if (category !== '0')
      $('#all').quicksand(apps[category], {adjustHeight: 'dynamic'});

    categoryButtons.on('click', (e) => {
      e.preventDefault();
      category = $(this).attr('data-category');
      console.log('category ' + category);
      $('#all').quicksand(apps[category], {adjustHeight: 'dynamic'});
    });
  }
};

yf.pageControllers.app = {
  initialize(page) {
    $('.banner').unslider({
      speed: 500,
      delay: 8000,
      complete() {},
      keys: true,
      dots: true,
      fluid: false
    });
  }
};

yf.pageControllers.reference = {
  initialize(page) {
    if (!Modernizr.objectfit) {
      $('.yf-gallery-item').each(() => {
        const $container = $(this);
        const img = $container.find('img');

        if (img) {
          const imgUrl = img.prop('src');
          $(img).detach();

          if (imgUrl)
            $container.css('backgroundImage', 'url(' + imgUrl + ')');
        }
      });
    }
  }
};

$(document).ready(() => {
  yf.baseController.initialize();

  const page = $('DIV[data-controller]');
  if (page) {
    const controllerName = page.attr('data-controller');
    if (yf.pageControllers[controllerName])
      yf.pageControllers[controllerName].initialize(page);
  }
});
